
.v-application {
    max-height: 100vh;
    position: relative;
    background-color: #e1f9f1;

    .swiper-container {
        max-width: 100%;
        max-height: 100vh;
        position: relative;
        img {
            display: block;
            margin: 0 auto;
            max-height: 100vh;
        }
        .swiper-pagination {
            top: 40px;
            bottom: initial;
        }
    }
}
.v-card {
    position: fixed;
    bottom: 68px;
    left: var(--grid-gutter);
    width: fit-content;
    max-width: calc(100% - var(--grid-gutter) * 2);
    z-index: 999;
}
.v-bottom-navigation {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) !important;
    z-index: 999;
    width: 100%;
    display: flex;
    align-items: center;
    .v-btn {
        height: 100% !important;
    }
}
