
::v-deep() {
    .v-dialog {
        position: relative;
        max-width: calc(100% - var(--container-gutter) * 2) !important;
    }
}
.v-btn {
    position: fixed;
    top: 44px;
    right: 16px;
}
