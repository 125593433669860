
::v-deep() {
    .v-dialog {
        position: relative;
        max-width: calc(100% - var(--container-gutter) * 2) !important;
        background: #fff;
    }
    .v-card {
        padding: 16px 0;
        &__title,
        &__text {
            font-size: var(--txt-font-size) !important;
        }
    }
}
.v-btn {
    position: absolute;
    top: 0;
    right: 0;
}
